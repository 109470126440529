import React, { useEffect, Fragment, useState } from 'react';
import Head from 'next/head';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from '../src/theme';
import '../styles/globals.css';
import Sidebar from '../src/common/Sidebar';
import SessionProvider, { useSession } from '../src/providers/SessionProvider';
import ApiClientProvider from '../src/providers/ApiClientProvider';
import NProgress from 'nprogress';
import Router from 'next/router';
import '../styles/nprogress.css';
import LoadingPage from '../src/common/LoadingPage';
import { useRouter } from 'next/router';

NProgress.configure({ showSpinner: true });

Router.events.on('routeChangeStart', (url) => {
    console.log(`Loading: ${url}`);
    NProgress.start();
});
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

export const ComponentContent = ({ Component, pageProps, pageLoading }) => {
    const { isLoggedIn, isNewUser, sessionLoading } = useSession();
    const router = useRouter();

    if (
        router.pathname === '/track/[slug]/[id]' ||
        router.pathname === '/track/[slug]' ||
        router.pathname === 'api-docs'
    ) {
        return <Component {...pageProps} />;
    }

    const content =
        pageLoading || sessionLoading ? (
            <LoadingPage />
        ) : (
            <Component {...pageProps} />
        );

    return isLoggedIn || isNewUser ? <Sidebar>{content}</Sidebar> : content;
};

export default function MyApp(props) {
    const [pageLoading, setPageLoading] = useState(false);

    useEffect(() => {
        const jssStyles = document.querySelector('#jss-server-side');
        if (jssStyles) {
            jssStyles.parentElement.removeChild(jssStyles);
        }

        Router.events.on('routeChangeStart', (url) => {
            setPageLoading(true);
        });

        Router.events.on('routeChangeComplete', () => setPageLoading(false));
        Router.events.on('routeChangeError', () => setPageLoading(false));
    }, []);

    return (
        <Fragment>
            <Head>
                <title>JNU OTS</title>
                <meta
                    name="viewport"
                    content="minimum-scale=1, initial-scale=1, width=device-width"
                />
                <link
                    href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
                    rel="stylesheet"
                />
            </Head>
            <ThemeProvider theme={theme}>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                <ApiClientProvider>
                    <SessionProvider>
                        {/* <ModalProvider> */}
                        <ComponentContent
                            {...props}
                            pageLoading={pageLoading}
                        />
                        {/* </ModalProvider> */}
                    </SessionProvider>
                </ApiClientProvider>
            </ThemeProvider>
        </Fragment>
    );
}
