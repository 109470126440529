import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import People from '@material-ui/icons/People';
import ExitToApp from '@material-ui/icons/ExitToApp';
import Receipt from '@material-ui/icons/Receipt';
import Settings from '@material-ui/icons/Settings';
import Dashboard from '@material-ui/icons/Dashboard';
import Policy from '@material-ui/icons/Policy';
import DescriptionIcon from '@material-ui/icons/Description';
import { useSession } from '../providers/SessionProvider';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { UserRoles } from '../../types/Users';
import { Box } from '@material-ui/core';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            height: '100%',
        },
        drawer: {
            [theme.breakpoints.up('md')]: {
                width: drawerWidth,
                flexShrink: 0,
            },
        },
        appBar: {
            [theme.breakpoints.up('md')]: {
                width: `calc(100% - ${drawerWidth}px)`,
                marginLeft: drawerWidth,
            },
        },
        menuButton: {
            marginRight: theme.spacing(2),
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
        // necessary for content to be below app bar
        logo: {
            paddingTop: 10,
            paddingBottom: 10,
            textAlign: 'center',
        },
        drawerPaper: {
            width: drawerWidth,
        },
        link: {
            textDecoration: 'none',
            color: '#000000de',
        },
        linkActive: {
            background: theme.palette.primary.main,
            color: '#fff',
            display: 'block',
            textDecoration: 'none',
        },
        content: {
            flexGrow: 1,
            paddingTop: '65px',
        },
        loggedInInfoWrap: {
            position: 'fixed',
            bottom: 0,
            width: '240px',
            padding: 0,
            background: '#0000000a',
            borderTop: '1px solid #ccc',
        },
        loggedInInfoList: {
            paddingTop: 3,
            paddingBottom: 3,
        },
        loggedInInfo: {
            fontSize: '12px',
            wordBreak: 'break-word',
        },
        navHeader: {
            '@media (max-width: 540px)': {
                fontSize: '14px',
            },
        },
    }),
);

export interface SidebarProps {
    children: Array<React.ReactNode> | React.ReactNode;
}

export interface MenuProps {
    name: string;
    icon: JSX.Element;
    link: string;
}

const UsersMenu = {
    name: 'Users',
    icon: <People />,
    link: '/users',
};

const DashboardMenu = {
    name: 'Dashboard',
    icon: <Dashboard />,
    link: '/dashboard',
};

const SettingsMenu = {
    name: 'Settings',
    icon: <Settings />,
    link: '/settings',
};

const OrdersMenu = {
    name: 'Orders',
    icon: <Receipt />,
    link: '/orders',
};

const ClientOrdersMenu = {
    name: 'Orders',
    icon: <Receipt />,
    link: '/orders/client',
};

const RiderOrdersMenu = {
    name: 'Orders',
    icon: <Receipt />,
    link: '/orders/rider',
};

const ApiDocsMenu = {
    name: 'Api Docs',
    icon: <DescriptionIcon />,
    link: '/api-docs',
};

const AdminMenu = [
    DashboardMenu,
    UsersMenu,
    OrdersMenu,
    ApiDocsMenu,
    SettingsMenu,
];

const ClientMenu = [DashboardMenu, ClientOrdersMenu, ApiDocsMenu, SettingsMenu];

const RiderMenu = [RiderOrdersMenu, SettingsMenu];

const MenuMapping = {
    [UserRoles.Admin]: AdminMenu,
    [UserRoles.Client]: ClientMenu,
    [UserRoles.Rider]: RiderMenu,
};

const Sidebar = ({ children, ...props }: SidebarProps) => {
    // const { window } = props;
    const classes = useStyles();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const { onLogout, userData, authToken } = useSession();
    const router = useRouter();
    const [sidebarMenu, setSidebarMenu] = useState<Array<MenuProps>>([]);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    useEffect(() => {
        const menu = MenuMapping[userData.role];

        setSidebarMenu(menu);
    }, [authToken, userData]);

    useEffect(() => {
        console.log(userData);
    }, []);

    const drawer = (
        <div>
            <div className={classes.logo}>
                <Image src="/jnu-logo.png" width="150px" height="80px" />
            </div>
            <Divider />
            <List>
                {sidebarMenu?.map((menu) => (
                    <li
                        className={
                            router.pathname === menu.link
                                ? classes.linkActive
                                : classes.link
                        }
                        key={menu.name}
                    >
                        <Link href={menu.link}>
                            <ListItem
                                button
                                onClick={() =>
                                    mobileOpen && handleDrawerToggle()
                                }
                            >
                                <ListItemIcon>{menu.icon}</ListItemIcon>
                                <ListItemText primary={menu.name} />
                            </ListItem>
                        </Link>
                    </li>
                ))}
            </List>
            <Divider />
            <List className={classes.loggedInInfoWrap}>
                <List>
                    <ListItem button onClick={onLogout}>
                        <ListItemIcon>
                            <ExitToApp />
                        </ListItemIcon>
                        <ListItemText primary="Logout" />
                    </ListItem>
                </List>
            </List>
        </div>
    );

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar className={classes.appBar} color="secondary">
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                        flexDirection="row"
                    >
                        <Typography
                            variant="h6"
                            noWrap
                            className={classes.navHeader}
                        >
                            JNU Order Tracking System
                        </Typography>
                        <Typography
                            variant="h6"
                            noWrap
                            className={classes.navHeader}
                        >
                            {userData?.role === UserRoles.Client
                                ? userData.client.name
                                : userData.role}
                        </Typography>
                    </Box>
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="">
                <Hidden lgUp implementation="css">
                    <Drawer
                        variant="temporary"
                        anchor="left"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: false, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>

                <Hidden smDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>{children}</main>
        </div>
    );
};

export default Sidebar;
