import { createMuiTheme } from '@material-ui/core/styles';
import {
    PRIMARY_ACCENT,
    RED_ACCENT,
    SECONDARY_ACCENT,
} from './common/colorConstants';

// Create a theme instance.
const theme = createMuiTheme({
    palette: {
        primary: {
            main: PRIMARY_ACCENT,
        },
        secondary: {
            main: SECONDARY_ACCENT,
        },
        error: {
            main: RED_ACCENT,
        },
        background: {
            default: '#fff',
        },
    },
    typography: {
        fontFamily: 'Poppins',
    },
});

export default theme;
