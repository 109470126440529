import { Client } from './Client';

export enum UserRoles {
    Client = 'Client',
    Admin = 'Admin',
    Rider = 'Rider',
}

export enum UserStatus {
    INACTIVE = 'INACTIVE',
    ACTIVE = 'ACTIVE',
    PENDING_ACTIVATION = 'PENDING_ACTIVATION',
}

export interface DBUser {
    id: number;
    first_name: string;
    last_name: string;
    email_address: string;
    role: UserRoles;
    password: string;
    active: number;
    status: UserStatus;
    contact_number: string;
    address: string;
    delete_lock: number;
    client_id: number;
}

export interface User {
    id: string;
    firstName: string;
    lastName: string;
    emailAddress: string;
    role: UserRoles;
    contactNumber?: string;
    address?: string;
    status: UserStatus;
    deleteLock?: number;
    client: Client | null;
}

export interface DBClient {
    api_key: string;
    name: string;
    id: number;
}

export interface UserSessionParams {
    userData: User;
    setUserData: React.Dispatch<React.SetStateAction<User>>;
    authToken: string | undefined;
    onLogin: (emailAddress: string, password: string) => Promise<any>;
    onLogout: () => void;
    isLoggedIn: boolean;
    isNewUser: boolean;
    setIsNewUser: React.Dispatch<React.SetStateAction<boolean>>;
    sessionLoading: boolean;
}
