import { useRouter } from 'next/router';
import React, { useContext, useState, createContext, useEffect } from 'react';
import { useApiClient } from './ApiClientProvider';
import { setUserCookie } from '../common/utils';

import {
    UserRoles,
    UserStatus,
    User,
    UserSessionParams,
} from '../../types/Users';

const DEFAULT_USER = {
    id: '',
    firstName: '',
    lastName: '',
    emailAddress: '',
    contactNumber: '',
    address: '',
    role: UserRoles.Rider,
    status: UserStatus.PENDING_ACTIVATION,
    client: null,
};

const DEFAULT_SESSION = {
    userData: DEFAULT_USER,
    setUserData: () => null,
    authToken: '',
    onLogin: (_emailAddress = '', _password = '') => Promise.resolve(null),
    onLogout: () => null,
    isLoggedIn: false,
    isNewUser: false,
    setIsNewUser: () => null,
    sessionLoading: false,
};

const SessionContext = createContext<UserSessionParams>(DEFAULT_SESSION);

export default function SessionProvider({
    children,
}: {
    children: Array<React.ReactNode> | React.ReactNode;
}) {
    const router = useRouter();
    const { authToken, invokeGetRequest, invokePostRequest, setAuthToken } =
        useApiClient();
    const [userData, setUserData] = useState<User>(DEFAULT_USER);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isNewUser, setIsNewUser] = useState(false);
    const [sessionLoading, setSessionLoading] = useState(false);

    const onLogin = async (emailAddress = '', password = '') => {
        try {
            const res = await invokePostRequest('/login', {
                emailAddress,
                password,
            });

            if (!res?.token) {
                throw new Error(res.errorMessage);
            }
            setSessionLoading(true);
            setAuthToken(res?.token);
            setSessionLoading(false);

            setUserCookie({
                authToken: res.authToken,
                id: res.user.id,
                role: res.user.role,
            });

            router.push(`/orders/`);
        } catch (e) {
            setSessionLoading(false);
            console.log('Error Loggin In:', e);
            return { error: true, message: e.message };
        }
    };

    const onLogout = () => {
        try {
            setSessionLoading(true);
            setUserData(DEFAULT_USER);
            setAuthToken('');
            console.log('DELETED localstorage');
            setIsLoggedIn(false);
            setIsNewUser(false);

            setUserCookie({
                authToken: '',
                id: '',
                role: '',
            });

            router.push('/login');
            setSessionLoading(false);
        } catch (e) {
            setSessionLoading(false);
        }
    };

    useEffect(() => {
        if (
            router.pathname === '/track/[slug]/[id]' ||
            router.pathname === '/track/[slug]' ||
            router.pathname === '/api-docs'
        ) {
            return;
        }

        if (!authToken) {
            onLogout();
            return;
        }

        (async function getMe() {
            try {
                setSessionLoading(true);
                const res = await invokeGetRequest('/me', {});

                console.log('user', res);
                if (res?.errorMessage) {
                    onLogout();
                }

                setIsNewUser(res?.status === UserStatus.PENDING_ACTIVATION);
                setUserData(res);
                setIsLoggedIn(true);
                setUserCookie({
                    authToken,
                    id: res.id,
                    role: res.role,
                });
                setSessionLoading(false);
            } catch (e) {
                router.push('/login');
                setSessionLoading(false);
            }
        })();
    }, [authToken]);

    useEffect(() => {
        if (isNewUser) {
            router.push('/change-password');
            return;
        }
    }, [isLoggedIn]);

    const providerValues = {
        userData,
        setUserData,
        authToken,
        onLogin,
        onLogout,
        isLoggedIn,
        isNewUser,
        setIsNewUser,
        sessionLoading,
    };

    return (
        <SessionContext.Provider value={providerValues}>
            {children}
        </SessionContext.Provider>
    );
}

export const useSession = () => useContext(SessionContext);
