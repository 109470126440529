import React from 'react';
import { CircularProgress, Box, makeStyles } from '@material-ui/core';
import Image from 'next/image';

const useStyles = makeStyles({
    container: {
        height: '100%',
        width: '100%',
    },
});
export default function LoadingPage() {
    const classes = useStyles();
    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            className={classes.container}
        >
            <CircularProgress />
        </Box>
    );
}
