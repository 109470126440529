/** @flow */

export const PRIMARY_ACCENT = 'rgb(30,139,174)';
export const SECONDARY_ACCENT = 'rgb(2, 67, 120)';
export const RED_ACCENT = 'rgb(231,56,69)';
export const WARNING_ACCENT = 'rgb(255,152,0)';
export const FB_COLOR = 'rgb(59,89,152)';
export const WHITE_COLOR = 'rgb(255,255,255)';
export const SECONDARY_BACKGROUND = 'rgb(242,250,239)';
export const TERTIARY_ACCENT = 'rgb(240, 250, 239)';
export const GREY_BORDER = 'rgb(169,180,186)';

export const EVENT_TYPE_RED = 'red';
export const EVENT_TYPE_YELLOW = 'yellow';
export const EVENT_TYPE_GREEN = 'green';
export const EVENT_TYPE_BLUE = 'blue';
export const EVENT_TYPE_VIOLET = 'violet';
export const EVENT_TYPE_ORANGE = 'orange';
